@media only screen and (min-width: 1440px) {
  .feature--img {
    width: 800px;
  }
  .feature--img--2 {
    width: 300px;
  }
  .feature--img--3 {
    width: 750px;
  }

  .carousel--cta {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }
}

/* @media only screen and (max-width: 992px) {
  .carousel--list {
    display: none;
  }
} */
