@media only screen and (max-width: 575px) {
  .blogs-fix-2 {
    padding: 0px;
  }

  .blogs-fix-1 {
    padding: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .recent--post {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 446px) {
  .card--header {
    flex-wrap: wrap;
  }
}

/* @media only screen and (min-width: 447px) {
  .card--header {
  }
} */

@media only screen and (min-width: 576px) {
  .blogs-fix-3 {
    margin-left: 25px;
    margin-right: 45px;
  }

  .blogs-fix-1 {
    margin-right: 0px;
    margin-left: 45px;
  }

  .recent--post {
    margin: 0 50px;
  }
}
