.home--cta {
  margin: 0 auto;
}

@media only screen and (max-width: 769px) {
  .hero--image {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 425px) {
  body {
    max-width: 100vw;
  }
  .video--wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home--cta {
    margin-left: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .home--cta {
    margin-left: -20px;
  }
}
