@import url(https://fonts.googleapis.com/css?family=Inter&amp;display=swap);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&amp;display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home--cta {
  margin: 0 auto;
}

@media only screen and (max-width: 769px) {
  .hero--image {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 425px) {
  body {
    max-width: 100vw;
  }
  .video--wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home--cta {
    margin-left: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .home--cta {
    margin-left: -20px;
  }
}

@media only screen and (min-width: 1440px) {
  .feature--img {
    width: 800px;
  }
  .feature--img--2 {
    width: 300px;
  }
  .feature--img--3 {
    width: 750px;
  }

  .carousel--cta {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }
}

/* @media only screen and (max-width: 992px) {
  .carousel--list {
    display: none;
  }
} */

@media only screen and (max-width: 575px) {
  .blogs-fix-2 {
    padding: 0px;
  }

  .blogs-fix-1 {
    padding: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .recent--post {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 446px) {
  .card--header {
    flex-wrap: wrap;
  }
}

/* @media only screen and (min-width: 447px) {
  .card--header {
  }
} */

@media only screen and (min-width: 576px) {
  .blogs-fix-3 {
    margin-left: 25px;
    margin-right: 45px;
  }

  .blogs-fix-1 {
    margin-right: 0px;
    margin-left: 45px;
  }

  .recent--post {
    margin: 0 50px;
  }
}

